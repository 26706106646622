// DOCEO

//──── Personnalisation globale des accordéons ─────────────────────────────────────────── 
$('.erpl_accordion .erpl_accordion-item .erpl_accordion-item-title .erpl_accordion-item-title-tools').on("click", e => {
    e.stopPropagation()
});

// Mise en avant de l'élément ouvert
$('.erpl_accordion .erpl_accordion-item-content').on('show.bs.collapse', function (e) {
    e.stopPropagation();
    $(this).closest('.erpl_accordion-item').addClass("position-relative border-primary");
});
$('.erpl_accordion .erpl_accordion-item-content').on('hide.bs.collapse', function (e) {
    e.stopPropagation();
    $(this).closest('.erpl_accordion-item').removeClass("position-relative border-primary");
});

// Scroll auto
$('.erpl_accordion .erpl_accordion-item-content').on('shown.bs.collapse', function (e) {
    console.log(e)
    e.stopPropagation();
    $('html, body').animate({
        scrollTop: $(this).closest('li.erpl_accordion-item').offset().top - 40
    }, 200);

}); 


//──── OJ Nav sync & selection ───────────────────────────────────────────────────────────
let syncOjList = function (startTime, endTime) {
    let $ojList = $('#ojAccordion.erpl_accordion');
    // Ouvrir le point dans la liste
    $ojList.find(`[data-start-time="${startTime}"][data-end-time="${endTime}"] .erpl_accordion-item-content`).collapse('show');
}

let syncOjNav = function (startTime, endTime) {
    let $ojNav = $('#ojNavAccordion.erpl_accordion');
    $ojNav.find('.erpl_accordion-item-content').collapse('show');

    // Sélectionner l'élément dans la navigation
    $ojNav.find('.erpl_links-list li').attr('data-selected', 'false');
    $ojNav.find(`[data-start-time="${startTime}"][data-end-time="${endTime}"]`).attr('data-selected', 'true');
}

// Clic sur élément de navigation
$('#ojNavAccordion.erpl_accordion .erpl_links-list li').on('click', function(e) {
    let startTime = $(this).attr('data-start-time');
    let endTime = $(this).attr('data-end-time');
    syncOjNav(startTime, endTime);
    syncOjList(startTime, endTime);
});

// à l'ouverture d'un élément de la liste
$('#ojAccordion.erpl_accordion .erpl_accordion-item-content').on('show.bs.collapse', function (e) {
    let item = this;
    let startTime = $(item).closest('.erpl_accordion-item[data-start-time][data-end-time]').attr('data-start-time');
    let endTime = $(item).closest('.erpl_accordion-item[data-start-time][data-end-time]').attr('data-end-time');
    syncOjNav(startTime, endTime);
}); 


//──── NAVIAGATION SPA ───────────────────────────────────────────────────────────────────
$('.spa-link').on('click', function(e){
    e.preventDefault();
    let target = $(this).attr('href') || $(this).attr('data-target');
    let title = $(this).attr('title');
    let data = $(this).attr('data-data');
    window.history.pushState(data, title, target);

})

//──── Toggle display Selector ───────────────────────────────────────────────────────────
/**
 * Permuter l'affichage des éléments cibles selon la valeur d'un élément <select>
 * selector : .doceo-toggle-selector
 * [data-toggle-target] : permet de définir le noeud à partir duquel, la recherche d'éléments est effectuée. Défaut : document
 * [data-toggle-selector-value] : Attribut à appliquer aux éléments cibles.
 * 
 */
document.querySelectorAll('select.doceo-toggle-selector').forEach(selectElement => {

    /* Définir la cible sur la valeur de l'attribut data-toggle-target sur l'élément select, ou si cet
    attribut n'est pas présent, il définit la cible sur le document. */
    let target = document.querySelector(selectElement.dataset.toggleTarget) || document;

    /* Création d'un tableau d'objets contenant l'élément et son élément parent. */
    let stock = Array.from(target.querySelectorAll(`[data-toggle-selector-value]`)).map(el => {
    
        return {
            element: el,
            container: el.parentElement
        }
    });
    /* Supprimer tous les éléments du DOM. */
    let clear = () => {
        stock.forEach(item => {
            item.element.classList.add('d-none')
            item.element.remove()
        });
    }
    clear();

    let update = () => {
   
        clear();
    
        // Afficher l'élément ciblé par la valeur
        stock.filter(item => {
            return item.element.dataset.toggleSelectorValue === selectElement.value;
        }).forEach(item => {
            item.element.classList.remove('d-none')
            item.container.appendChild(item.element)
        })
    }

    if (selectElement.value) {
        update()
    }

    selectElement.addEventListener('change', () => {
        update()
    })
    
})


/** RING 
 * 
 * Composant Ring
 * Attribution des classes selon le statut actif des éléments.
 * Observation des mutations
 * 
*/
$.fn.doceoRing = function () {
    console.log(this);

    let $steps = $(this).find('.doceo-ring-steps-step');
    let updateSteps = () => {
        $steps.each(function() {

            if ($(this).next() && $(this).next().hasClass('active')) {
                $(this).addClass('next-is-active')
            } else {
                $(this).removeClass('next-is-active')
            }
        });
    }

    updateSteps();

    let mutationObserver = new MutationObserver(function(mutationsList) {
        console.log(mutationsList);
        updateSteps();

    });

    mutationObserver.observe(this[0], { attributes: true, subtree: true })

}
$('.doceo-ring').each(function(){

    $(this).doceoRing();

})